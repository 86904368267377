import { dkConstants } from './dk-constants';

export const updateListElement = (list, newElement, identifier = 'id') => {
  const idx = list.findIndex(
    (item) => item[identifier] === newElement[identifier]
  );
  if (idx >= 0) {
    list.splice(idx, 1, newElement);
  }
};

// eslint-disable-next-line require-jsdoc
export function debounce(fn, timeout = dkConstants.DEFAULT_DEBOUNCE_DURATION) {
  let timer;
  return function (...args) {
    if (timer) {
      clearTimeout(timer);
    }
    // eslint-disable-next-line no-invalid-this
    const context = this;
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, timeout);
  };
}
