<template>
  <div id="app" class="App">
    <header class="app-header">
      <AppBar />
    </header>

    <b-container fluid>
      <section class="app-content">
        <router-view />
      </section>
    </b-container>

    <ErrorModal />
    <GlobalToast />
  </div>
</template>

<script>
// UI5 Icons
import '@ui5/webcomponents-icons/dist/AllIcons.js';
import '@ui5/webcomponents-icons/dist/add.js';
import '@ui5/webcomponents-icons/dist/clear-filter.js';
import '@ui5/webcomponents-icons/dist/collapse-all.js';
import '@ui5/webcomponents-icons/dist/dropdown.js';
import '@ui5/webcomponents-icons/dist/employee.js';
import '@ui5/webcomponents-icons/dist/error.js';
import '@ui5/webcomponents-icons/dist/filter.js';
import '@ui5/webcomponents-icons/dist/group.js';
import '@ui5/webcomponents-icons/dist/home.js';
import '@ui5/webcomponents-icons/dist/less.js';
import '@ui5/webcomponents-icons/dist/log.js';
import '@ui5/webcomponents-icons/dist/notification-2.js';
import '@ui5/webcomponents-icons/dist/search.js';
import '@ui5/webcomponents-icons/dist/settings.js';
import '@ui5/webcomponents-icons/dist/sys-help.js';
import '@ui5/webcomponents-icons/dist/documents.js';

// UI5 Components
import '@ui5/webcomponents-fiori/dist/NotificationListItem.js';
import '@ui5/webcomponents-fiori/dist/NotificationAction.js';
import '@ui5/webcomponents-fiori/dist/ShellBar.js';
import '@ui5/webcomponents-fiori/dist/Timeline.js';
import '@ui5/webcomponents/dist/Avatar.js';
import '@ui5/webcomponents/dist/Badge.js';
import '@ui5/webcomponents/dist/Card.js';
import '@ui5/webcomponents/dist/CardHeader.js';
import '@ui5/webcomponents/dist/CheckBox.js';
import '@ui5/webcomponents/dist/CustomListItem.js';
import '@ui5/webcomponents/dist/DatePicker.js';
import '@ui5/webcomponents/dist/DateTimePicker.js';
import '@ui5/webcomponents/dist/Dialog.js';
import '@ui5/webcomponents/dist/features/InputElementsFormSupport.js';
import '@ui5/webcomponents/dist/features/InputSuggestions.js';
import '@ui5/webcomponents/dist/Icon.js';
import '@ui5/webcomponents/dist/Input.js';
import '@ui5/webcomponents/dist/Label.js';
import '@ui5/webcomponents/dist/List.js';
import '@ui5/webcomponents/dist/MessageStrip.js';
import '@ui5/webcomponents/dist/Option.js';
import '@ui5/webcomponents/dist/Panel.js';
import '@ui5/webcomponents/dist/RadioButton.js';
import '@ui5/webcomponents/dist/Select.js';
import '@ui5/webcomponents/dist/StandardListItem.js';
import '@ui5/webcomponents/dist/Switch.js';
import '@ui5/webcomponents/dist/Tab.js';
import '@ui5/webcomponents/dist/TabContainer.js';
import '@ui5/webcomponents/dist/Table.js';
import '@ui5/webcomponents/dist/TableCell.js';
import '@ui5/webcomponents/dist/TableColumn.js';
import '@ui5/webcomponents/dist/TableRow.js';
import '@ui5/webcomponents/dist/TabSeparator.js';
import '@ui5/webcomponents/dist/TextArea.js';
import '@ui5/webcomponents/dist/Toast.js';
import '@ui5/webcomponents/dist/FileUploader';

import AppBar from './views/AppBar.vue';
import ErrorModal from './components/ErrorModal.vue';
import GlobalToast from './components/common/GlobalToast.vue';

export default {
  name: 'App',

  components: {
    GlobalToast,
    AppBar,
    ErrorModal,
  },
};
</script>

<style>
html,
body,
.root,
.App {
  padding: 0;
  margin: 0;
  background: var(--sapShell_Background, #fafafa);
}
/* .app-content {
  padding: 2rem;
} */
.section {
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.ui5card {
  align-self: flex-start;
  min-width: 18rem;
  max-width: 20rem;
  margin: 0.5rem 1rem 1rem 0;
}
.ui5card-large {
  min-width: 30rem;
  max-width: 60%;
  flex: 1 1;
}
.card-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.card-content-child {
  flex: 1 1;
}
.ui5icon-size,
.ui5label-size {
  font-size: 1.75rem;
}
.ui5list-item {
  font-size: 1.75rem;
  --sapUiContentNonInteractiveIconColor: #1a9898;
}
/* Action alerts */
.ui5card-alert {
  min-width: 12rem;
  width: 12rem;
}
.ui5card-alert-content {
  display: flex;
  align-items: flex-end;
  padding: 1rem;
  height: 6rem;
}
.ui5card-alert-icon {
  margin: 0 0.5rem;
}
/* Profile Area */
.profile-header {
  flex-direction: column;
  padding: 1rem;
}
.profile-content {
  min-width: 17rem;
  padding: 0 0.25rem;
}
.profile-img {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
}
.profile-hcb-switch {
  width: 100%;
}

/* Semantic colors */
.error {
  color: var(--sapNegativeColor, #bb0000);
}

.modal-input {
  margin: 0.5rem;
}

.full-width {
  width: 100%;
}
</style>
